// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import $ from 'jquery';
// eslint-disable-next-line import/no-unresolved
// import 'channels';

import 'jquery';
import 'popper.js';
import 'bootstrap';
import '@hotwired/turbo-rails';

Turbo.session.drive = false
// import Sortable from "sortablejs";

require('@rails/ujs').start();
// require('turbolinks').start();
require('@rails/activestorage').start();

// Cherrypick extra plugins
// import Sortable, { MultiDrag, Swap } from "sortablejs";

// Cherrypick default plugins
// import Sortable, { AutoScroll } from "sortablejs/modular/sortable.core.esm.js";

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

global.$ = jQuery;

function showToast() {
  if (document.getElementById('toast-text') !== null) {
    $.get(`/attachments.json?pending='true'`, (attachments) => {
      $('#toast-text')
        .html('')
        .html(`Existem ${attachments.length} documentos pendentes.`);
    });
    setTimeout(() => {
      $('.toast')
        .toast({
          autohide: false,
        })
        .toast('show');
    }, 3000);
  }
}

function watchImports() {
  const $id = $('#page-function-id');

  if ($id.length > 0) {
    const fileName = $id.val();
    switch (fileName) {
      case 'route-sections-form':
        import('../views/route_sections/form');
        break;
      case 'route-sections-index':
        import('../views/route_sections/index');
        break;
      case 'commercial-routes-form':
        import('../views/commercial_routes/form');
        break;
      case 'commercial-routes-index':
        import('../views/commercial_routes/index');
        break;
      case 'dashboard':
        import('../views/dashboard');
        break;
      case 'operation-dashboard':
        import('../views/dashboard/operations');
        break;
      case 'operation-dashboard-today':
        import('../views/dashboard/operations_today');
        break;
    }
  }
}

function checkAlerts() {
  if ($('.status-alert').is(':visible')) {
    setTimeout(function () {
      $('.status-alert').alert('close');
    }, 3000);
  }
}

$(() => {
  checkAlerts();
  watchImports();
  $('.dropdown-menu a.dropdown-toggle').on('click', function (e) {
    if (!$(this).next().hasClass('show')) {
      $(this)
        .parents('.dropdown-menu')
        .first()
        .find('.show')
        .removeClass('show');
    }
    const $subMenu = $(this).next('.dropdown-menu');
    $subMenu.toggleClass('show');

    $(this)
      .parents('li.nav-item.dropdown.show')
      .on('hidden.bs.dropdown', function (e) {
        $('.dropdown-submenu .show').removeClass('show');
      });

    return false;
  });
  watchImports();

  // setInterval(() => {
  //   showToast();
  // }, 300000);
});
